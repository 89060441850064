body {
  background-color: #f0f0f7 !important;
  /* transition: 0.5s; */
  /* min-height: 100vh; */
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.ant-carousel .slick-dots li.slick-active button {
  background-color: #282c34 !important;
}

.ant-carousel .slick-dots li {
  margin-top: 50;
  background-color: #888888 !important;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.full-height {
  height: 100vh;
  overflow-y: scroll;
  box-shadow: "0 6px 20px 0 rgba(0, 0, 0, 0.16)";
}
.drp-card {
  padding: 20px;
  border-radius: 30px;
  box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.16);
  width: 100%;
  height: 100%;
  background-color: white;
}
.drp-btn-green {
  background-color: #23d098 !important;
  color: white !important;
  height: 40px !important;
  border-radius: 20px !important;
}
.btn-small {
  font-size: 12px !important;
  height: 36px !important;
  min-width: 100px;
}
.drp-font-1 {
  font-family: Nunito-Regular;
}
/* .ant-image-mask{} */
.ant-carousel .slick-prev,
.ant-carousel .slick-next {
  display: block !important;
  background: red !important;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.drp-input {
  border: 0px;
  height: auto;
  border-radius: 20px;
  width: 250px;
  background-color: #f4f6f9;
  padding: 15px;
  margin: 15px;
}
