.nav-route {
  height: 54px;
  font-size: large;
  align-items: center;
  color: "inherit";
}

.navbar-brand {
  background-color: #73727210 !important;
}

.nav-route:hover {
  text-decoration: none;
}

.nav-route-active {
  color: #a3a0fb;
  background-color: #f0f0f7;
  border-left: 7px solid #a3a0fb;
  transition: 0.3s;
  box-shadow: inset 0 0 1px #afafaf;
}

.nav-route-active #left-highlight {
  background-color: #a3a0fb;
  border-left: 1px solid #a3a0fb;
  margin-left: 0px;
}

.nav-route-active #icon {
  color: #a3a0fb !important;
}
